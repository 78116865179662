var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"font-family":"Inter"}},[_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"col-12"},[_c('q-img',{staticClass:"xs-hide",attrs:{"src":require('@/assets/Banners/noticias.jpg')}}),_c('q-img',{staticClass:"sm-hide md-hide lg-hide xl-hide",attrs:{"src":require('@/assets/Banners/noticias_mobile.jpg')}})],1),_c('div',{staticClass:"col-10 text-center text-bold q-my-md",staticStyle:{"color":"#4D4D4D","font-size":"25px"}},[_vm._v(" Oficinas y Teléfonos municipales ")]),_c('div',{staticClass:"col-10"},[_c('q-separator',{attrs:{"inset":""}})],1)]),_c('div',{staticClass:"row justify-center",staticStyle:{"max-width":"14400px","margin":"auto"}},[_c('div',{staticClass:"row col-10"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-12"},[_c('q-separator',{attrs:{"inset":""}})],1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"col-12"},[_c('q-separator',{attrs:{"inset":""}})],1),_vm._m(4),_vm._m(5),_c('div',{staticClass:"col-12"},[_c('q-separator',{attrs:{"inset":""}})],1),_vm._m(6),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"text-bold",staticStyle:{"color":"#B62979"}},[_vm._v(" Nombre del departamento ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12"},[_c('p',[_vm._v("Nombre Persona Encargada")]),_c('p',[_vm._v("Correo electrónico, Teléfono y Dirección")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"text-bold",staticStyle:{"color":"#B62979"}},[_vm._v(" Nombre del departamento ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12"},[_c('p',[_vm._v("Nombre Persona Encargada")]),_c('p',[_vm._v("Correo electrónico, Teléfono y Dirección")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"text-bold",staticStyle:{"color":"#B62979"}},[_vm._v(" Nombre del departamento ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12"},[_c('p',[_vm._v("Nombre Persona Encargada")]),_c('p',[_vm._v("Correo electrónico, Teléfono y Dirección")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"text-bold",staticStyle:{"color":"#B62979"}},[_vm._v(" Nombre del departamento ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-12"},[_c('p',[_vm._v("Nombre Persona Encargada")]),_c('p',[_vm._v("Correo electrónico, Teléfono y Dirección")])])
}]

export { render, staticRenderFns }
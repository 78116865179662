
import { Component, Vue } from "vue-property-decorator";
import NuestraRed from "@/components/Menu/NuestraRed.vue";

@Component({
  components: {
    NuestraRed
  }
})
export default class ConcejoView extends Vue {}

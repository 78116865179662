
import { Component, Vue } from "vue-property-decorator";
import TramitesSalud from "@/components/Menu/TramitesSalud.vue";

@Component({
  components: {
    TramitesSalud
  }
})
export default class TramitesSaludView extends Vue {
  private mounted() {
    document.title = "Trámites de Salud - Maipú Salud";
  }
}


import { Component, Vue } from "vue-property-decorator";
import menu_destacado from "@/components/Common/Web/menu_destacado.json";

@Component
export default class QuienesSomos extends Vue {
  private menuLateral = menu_destacado;
  private dataPagina: any = [];
  private active = false;
  private pagina: any = {
    id: "",
    titulo: "",
    nombre_web: "",
    fecha: "",
    banner: "",
    banner_mobile: "",
    contenido: "",
    direccion: "",
    telefono: "",
    email: "",
    horario: "",
    director: "",
    fecha_modificacion: "",
    tipo: "",
    estado: "",
    menu_lateral: ""
  };
  private nombreWeb: any = "";

  private mounted() {
    this.getPagina(this.menuLateral[0].nombre_web);
  }

  private getPagina(nombre_web) {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("pagina?nombre_web=" + nombre_web)
      .then((res: any) => {
        this.pagina = res.data;
        if (this.pagina.banner) {
          this.pagina.banner =
            "url_media/" + this.pagina.banner;
        } else {
          this.pagina.banner =
            "url_media/bannerPagina/default.jpg";
        }
        if (this.pagina.banner_mobile) {
          this.pagina.banner_mobile =
            "url_media/" + this.pagina.banner_mobile;
        } else {
          this.pagina.banner_mobile =
            "url_media/bannerPagina/default_mobile.jpg";
        }
        document.title = this.pagina.titulo + " - Maipú Salud";

        //MetaData generica
        document.title = this.pagina.titulo + " - Maipú Salud";
        (document.querySelector(
          'meta[name="description"]'
        ) as any).setAttribute("content", "Maipú Salud");
        (document.querySelector('meta[name="image"]') as any).setAttribute(
          "content",
          this.pagina.banner_mobile
        );

        //MetaData Twitter
        (document.querySelector(
          'meta[name="twitter:title"]'
        ) as any).setAttribute(
          "content",
          this.pagina.titulo + " - Maipú Salud"
        );
        (document.querySelector(
          'meta[name="twitter:description"]'
        ) as any).setAttribute("content", "Maipú Salud");
        (document.querySelector(
          'meta[name="twitter:image:src"]'
        ) as any).setAttribute("content", this.pagina.banner_mobile);

        //MetaData Facebook
        (document.querySelector(
          'meta[property="og:title"]'
        ) as any).setAttribute(
          "content",
          this.pagina.titulo + " - Maipú Salud"
        );
        (document.querySelector(
          'meta[property="og:description"]'
        ) as any).setAttribute("content", "Maipú Salud");
        (document.querySelector(
          'meta[property="og:image"]'
        ) as any).setAttribute("content", this.pagina.banner_mobile);

        if (this.pagina.director && this.pagina.director != "") {
          this.dataPagina.push({
            nombre: "Director/a",
            dato: this.pagina.director
          });
        }
        if (this.pagina.direccion && this.pagina.direccion != "") {
          this.dataPagina.push({
            nombre: "Dirección",
            dato: this.pagina.direccion
          });
        }
        if (this.pagina.email && this.pagina.email != "") {
          this.dataPagina.push({
            nombre: "Correo",
            dato: this.pagina.email
          });
        }
        if (this.pagina.telefono && this.pagina.telefono != "") {
          this.dataPagina.push({
            nombre: "Teléfono",
            dato: this.pagina.telefono
          });
        }
        if (this.pagina.horario && this.pagina.horario != "") {
          this.dataPagina.push({
            nombre: "Horario",
            dato: this.pagina.horario
          });
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
        this.routerGo("Home");
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private selectedStyle(nombre_web: string) {
    if (nombre_web == this.pagina.nombre_web) {
      return " background-color: #10174a !important; color: #f1f1f1 !important;font-family: 'Avenir NextBold' !important;";
    } else {
      return "";
    }
  }
}


import { Component, Vue } from "vue-property-decorator";
import Telefonos from "@/components/Web/Telefonos.vue";

@Component({
  components: {
    Telefonos
  }
})
export default class TelefonosView extends Vue {}


import { Component, Vue } from "vue-property-decorator";
import QuienesSomos from "@/components/Menu/QuienesSomos.vue";

@Component({
  components: {
    QuienesSomos
  }
})
export default class ConcejoView extends Vue {}
